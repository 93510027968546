const swiperPromo = () => {

  const pswiper = new Swiper(".myPromoSwiper", {
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  });
}

export { swiperPromo }
